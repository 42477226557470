exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-allgemeine-geschaeftsbedingungen-tsx": () => import("./../../../src/pages/allgemeine-geschaeftsbedingungen.tsx" /* webpackChunkName: "component---src-pages-allgemeine-geschaeftsbedingungen-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kanzlei-tsx": () => import("./../../../src/pages/kanzlei.tsx" /* webpackChunkName: "component---src-pages-kanzlei-tsx" */),
  "component---src-pages-karriere-tsx": () => import("./../../../src/pages/karriere.tsx" /* webpackChunkName: "component---src-pages-karriere-tsx" */),
  "component---src-pages-magazin-tsx": () => import("./../../../src/pages/magazin.tsx" /* webpackChunkName: "component---src-pages-magazin-tsx" */),
  "component---src-pages-services-checkliste-tsx": () => import("./../../../src/pages/services/checkliste.tsx" /* webpackChunkName: "component---src-pages-services-checkliste-tsx" */),
  "component---src-pages-services-covid-19-tsx": () => import("./../../../src/pages/services/covid19.tsx" /* webpackChunkName: "component---src-pages-services-covid-19-tsx" */),
  "component---src-pages-services-grundsteuer-tsx": () => import("./../../../src/pages/services/grundsteuer.tsx" /* webpackChunkName: "component---src-pages-services-grundsteuer-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-services-video-tsx": () => import("./../../../src/pages/services/video.tsx" /* webpackChunkName: "component---src-pages-services-video-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */)
}

